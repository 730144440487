<template>
  <vue-final-modal
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-model="showModal"
    :click-to-close="false"
  >
    <!-- layer :  추천 상품 결과 노출 - 정보 불러오기 -->
    <section id="modalResultItemAdd" class="layer_dialog modalin">
      <h2 class="mb20">추천 상품 - 결과화면 노출항목 불러오기</h2>

      <select name="" style="width: 274px">
        <option>[이미지] 노출항목명1</option>
        <option>[이미지] 노출항목명2</option>
        <option>[이미지] 노출항목명3</option>
      </select>
      <button type="button" class="btn btn_large btn_light">추가</button>
      <ul class="item_list">
        <li>
          <button type="button" name="">
            <span class="material-icons">cancel</span>
          </button>
          [이미지] 노출항목명1
        </li>
        <li>
          <button type="button" name="">
            <span class="material-icons">cancel</span>
          </button>
          [이미지] 노출항목명1
        </li>
        <li>
          <button type="button" name="">
            <span class="material-icons">cancel</span>
          </button>
          [이미지] 노출항목명1
        </li>
        <li>
          <button type="button" name="">
            <span class="material-icons">cancel</span>
          </button>
          [이미지] 노출항목명1
        </li>
      </ul>

      <div class="btns">
        <ul>
          <li>
            <button
              type="button"
              @click="showModal = false"
              class="btn btn_large btn_secondary"
            >
              <span>닫기</span>
            </button>
          </li>
          <li>
            <button
              type="button"
              @click="setResult"
              class="btn btn_large btn_primary"
            >
              <span>저장</span>
            </button>
          </li>
        </ul>
      </div>
    </section>
    <!-- //layer : 추천 상품 결과 노출 - 정보 불러오기 -->
  </vue-final-modal>
</template>

<script>
export default {
  inheritAttrs: false,
  data: () => ({
    showModal: true,
  }),
  props: {
    image: String,
  },
  created: function () {
    //console.log('image : ', this.image);
  },
  computed: {},
  methods: {
    setResult: function () {},
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
